<style lang="scss">
</style>
<template>
    <el-select class="resource-supplier"
               ref="select"
               filterable
               :clearable="clearable"
               style="width: 100%"
               :disabled="disabled"
               @change="handleChange"
               v-model="form.id"
               placeholder="供应商">
        <el-option
            v-for="item in supplierList"
            :key="item.id"
            :label="item.supplierName"
            :value="item.id"
        >
        </el-option>
    </el-select>

</template>

<script type="text/ecmascript-6">
export default {
    //定义模版数据
    data() {
        return {
            form: {
                id: null
            },
            supplierList: []
        }
    },
    props: {
        operatorId: {
            type: String,
            default: null
        },
        userType: {
            type: String,
            default: null
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: null
        },
        clearable: {
            type: Boolean,
            default: true
        }
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        }
    },
    //主件被加载完成
    mounted() {
        this.getSupplier();
    },
    //定义事件方法
    methods: {
        blur() {
            this.$refs.select.blur()
        },
        async getSupplier() {
            let userType = this.userType || this.userInfo.userType;
            let ret = null;
            switch (userType) {
                //总部
                case '0':
                    ret = await this.http('/galaxySupplier/doGalaxyGetSupplierList', {}, "POST");
                    break;
                case '1':
                    //当前是供应商
                    ret = {
                        success: true,
                        data: [{
                            id: this.userInfo.refId,
                            supplierName: this.userInfo.companyName
                        }]
                    }
                    break;
                case '2':
                    //运营商 请提供运营商id
                    ret = await this.http('/galaxySupplier/doGalaxyGetSupplierInfoByOperatorId', {operatorId: this.operatorId}, "POST");
                    break;
            }
            let list = [];
            if (ret.success && ret.data)
                list = ret.data;
            this.supplierList = list;
            return true;
        },
        handleChange(val) {
            this.$emit('input', val);
            this.$emit('change', this.supplierList.find((item) => item.id === val) || {});
        },
    },
    //监听模版变量
    watch: {
        operatorId() {
            //根据运营商联动
            this.getSupplier();
        },
        value() {
            this.form.id = this.value;
        }
    }

}
</script>
