<style lang="scss" scoped>
.driver-detail {
    .driver-section-cont {
        .box-card {
            margin-top: 10px;
            padding-bottom: 5px;
        }

        .box-title {
            height: 48px;
            border-bottom: 1px solid #f8f8f8;
            font-weight: 500;
            color: rgba(51, 51, 51, 1);
            line-height: 48px;
            padding-left: 20px;
        }

        .driver-box-card {
            padding: 10px;

            p {
                margin-bottom: 10px;
            }

            .verify-status {
                float: left;
                margin-left: 10px;
                height: 32px;
                width: 179px;
                font-size: 14px;
                font-weight: 400;
                line-height: 32px;
                border-radius: 4px;
                text-align: center;
            }

            .verify-default {
                background: #fff6e7;
                color: rgba(219, 145, 23, 1);
                border: 1px solid #db9117;
            }

            .verify-default1 {
                background: rgba(228, 255, 220, 1);
                color: rgba(32, 146, 139, 1);
                border: 1px solid rgba(32, 146, 139, 1);
            }

            .verify-success {
                background: rgba(240, 248, 255, 1);
                color: rgba(24, 144, 255, 1);
                border: 1px solid rgba(25, 137, 250, 1);
            }

            .verify-refuse {
                background: rgba(255, 239, 238, 1);
                border: 1px solid rgba(255, 59, 48, 1);
                color: rgba(255, 59, 48, 1);
            }

            .verify-btn {
                width: 121px;
                height: 32px;
                margin-right: 20px;
            }
        }

        .label-title {
            border-left: 2px solid #319bf7;
            padding-left: 10px;
            font-weight: 700;
            font-size: 14px;
            margin-left: 10px;
        }
    }

    .table {
        margin-left: 10px;

        tr td {
            width: 190px;
            height: 34px;
            border: 1px solid rgba(220, 223, 230, 1);
            font-weight: 500;
            line-height: 20px;
            font-size: 14px;
            padding-left: 10px;
        }

        .table-title {
            background: rgba(240, 242, 245, 1);
            color: rgba(144, 147, 153, 1);
        }

        .insur td {
            height: 51px;
            padding: 0 5px;

            div {
                margin-bottom: 0;
            }
        }
    }

    .excel-file-btns {
        text-align: center;

        a {
            position: relative;
            display: inline-block;
        }

        .up-form {
            display: inline-block;
        }

        input {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            outline: none;
            opacity: 0;
        }

        input[type="file"] {
            font-size: 0
        }

        .up-form:hover, .upload:hover, a:hover {
            cursor: pointer;
        }

    }
}
</style>
<style lang="scss">
.driver-section-cont {
    .el-input.is-disabled {
        .el-input__inner {
            color: #777;
            background-color: #f6f6f6;
        }
    }

    .el-card__body {
        padding: 0;
    }

    table {
        .el-input__inner {
            border: 0;
        }
    }

    /*.preview_img {*/
    /*line-height: initial;*/
    /*}*/
    .preview_img {
        line-height: initial;
    }

}
</style>

<template>
    <div class="driver-detail">
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="driver-section-cont">
                <el-row>
                    <el-form label-position="right" label-width="120px" size="small" :model="form" :rules="ruleForm"
                             ref="ruleForm">
                        <el-card class="box-card">
                            <p class="box-title">车辆基本信息</p>
                            <div class="driver-box-card clear">
                                <p style="margin-top: 5px">
                                    <span class="label-title">状态信息</span>
                                </p>
                                <div class="verify-status" :class="formatStatus(form.carAuditStatus).class">
                                    车辆基本信息：{{ formatStatus(form.carAuditStatus).label }}
                                </div>
                                <div style="float:right" v-if="!isEdit">
                                    <el-button type="success"
                                               size="small"
                                               class="verify-btn"
                                               @click="isEdit=true"
                                               v-if="form.carAuditStatus!==2||isCapacityManager">编辑
                                    </el-button>
                                    <el-button
                                        v-if="form.carAuditStatus==1||(form.carAuditStatus==4&&userInfo.refType!=1)"
                                        class="verify-btn"
                                        type="primary"
                                        size="small"
                                        @click="verifyDialog(1)">
                                        审核
                                    </el-button>
                                    <el-button
                                        @click="handleInfoExport"
                                        type="primary"
                                        size="small">台账导出
                                    </el-button>
                                </div>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">行驶证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="车辆营运城市：" prop="transArea">
                                        <el-select v-model="form.transArea" filterable placeholder="填写城市"
                                                   :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="city in serverCityList"
                                                :key="city.shortCode"
                                                :label="city.name"
                                                :value="city.name"
                                            ></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="所属供应商：" prop="supplierId">
                                        <supplier-select v-model="form.supplierId" :disabled="!isEdit"
                                                         :clearable="false"></supplier-select>
                                    </el-form-item>
                                    <el-form-item label="车牌号码：" prop="carNumberPlate">
                                        <el-input v-model="form.carNumberPlate" :maxLength="8" :disabled="!isEdit">
                                            <driver-vehicle-verification slot="suffix" :type="1" :info="form"/>
                                        </el-input>
                                    </el-form-item>
                                    <el-form-item label="车牌颜色：" prop="plateColor">
                                        <el-select v-model="form.plateColor" :disabled="!isEdit" style="width: 100%">
                                            <el-option value="蓝色"></el-option>
                                            <el-option value="绿色"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="证件车辆类型：" prop="licenseCarType">
                                        <el-input v-model="form.licenseCarType" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆所有人：" prop="carOwner">
                                        <el-input v-model="form.carOwner" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="使用性质：" prop="useCharacter">
                                        <el-input v-model="form.useCharacter" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <!--                                    <el-form-item label="证件品牌型号：" prop="licenseCarModel">-->
                                    <el-form-item label="证件品牌型号：">
                                        <el-input v-model="form.licenseCarModel" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆识别代码：" prop="vin">
                                        <el-input v-model="form.vin" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="发动机号码：" prop="engineId">
                                        <el-input v-model="form.engineId" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆注册日期：" prop="carRegisterDate">
                                        <el-date-picker type="date" v-model="form.carRegisterDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="发证日期：" prop="issueDate">
                                        <el-date-picker type="date" v-model="form.issueDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="核定载客人数：" prop="seats">
                                        <el-input v-model="form.seats" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="下次年检日期：" prop="nextFixDate">
                                        <el-date-picker type="date" v-model="form.nextFixDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd"
                                                        :style="{width: isSupplier?'auto':'100%'}">
                                        </el-date-picker>
                                        <EditPopover v-if="isSupplier" :info="form" :submit-api="submit"/>
                                    </el-form-item>
                                    <el-form-item label="平台品牌型号：" prop="carBrandModel">
                                        <brand-model v-model="form.carBrandModel" :disabled="!isEdit"
                                                     @selectBrand="selectBrand"></brand-model>
                                    </el-form-item>
                                    <el-form-item label="平台车辆类型：" prop="carType">
                                        <seat-model v-model="form.carType" :disabled="true"></seat-model>
                                    </el-form-item>
                                    <el-form-item label="车身颜色：" prop="carColor">
                                        <!--<el-input v-model="form.carColor" :disabled="!isEdit"></el-input>-->
                                        <el-select v-model="form.carColor" :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="car in vehicleColors"
                                                :key="car"
                                                :label="car"
                                                :value="car"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="燃油类型：" prop="fuelType">
                                        <!--<el-input v-model="form.fuelType" :disabled="!isEdit"></el-input>-->
                                        <el-select v-model="form.fuelType" :disabled="!isEdit" style="width: 100%">
                                            <el-option
                                                v-for="item in fuelTypes"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="发动机排量：" prop="engineDisplace">
                                        <el-select v-model="form.engineDisplace" :disabled="!isEdit"
                                                   style="width: 100%">
                                            <el-option
                                                v-for="item in engineDisplaces"
                                                :key="item"
                                                :label="item"
                                                :value="item"
                                            >
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="车辆轴距：" prop="wheelbase">
                                        <el-input v-model="form.wheelbase" :disabled="true"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="行驶证正面：">
                                        <div>
                                            <div v-if="form.carLicenseFacePhoto" class="preview_img">
                                                <el-image lazy
                                                          style="width: 286px;height: 186px;"
                                                          :src="form.carLicenseFacePhoto"
                                                          :preview-src-list="[form.carLicenseFacePhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                        </div>
                                        <file-upload id="carLicenseFacePhoto"
                                                     v-if="isEdit"
                                                     :showFileList="false"
                                                     :customStyle="true"
                                                     @onCompleted="handleImageUpload">
                                            <div style="text-align: center;width: 286px;">
                                                <el-button>
                                                    <i class="icon icon-upload-arrow"></i>
                                                    上传图片
                                                </el-button>
                                            </div>
                                        </file-upload>
                                    </el-form-item>
                                    <el-form-item label="行驶证反面：">
                                        <div>
                                            <div v-if="form.carLicenseBackPhoto" class="preview_img">
                                                <el-image lazy
                                                          style="width: 286px;height: 186px;"
                                                          :src="form.carLicenseBackPhoto"
                                                          :preview-src-list="[form.carLicenseBackPhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                        </div>
                                        <file-upload id="carLicenseBackPhoto"
                                                     v-if="isEdit"
                                                     :showFileList="false"
                                                     :customStyle="true"
                                                     @onCompleted="handleImageUpload">
                                            <div style="text-align: center;width: 286px;">
                                                <el-button>
                                                    <i class="icon icon-upload-arrow"></i>
                                                    上传图片
                                                </el-button>
                                            </div>
                                        </file-upload>
                                    </el-form-item>
                                    <el-form-item label="车辆照片：">
                                        <div>
                                            <div v-if="form.carPhoto" class="preview_img">
                                                <el-image lazy
                                                          style="width: 286px;height: 186px;"
                                                          :src="form.carPhoto"
                                                          :preview-src-list="[form.carPhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                        </div>
                                        <file-upload id="carPhoto"
                                                     v-if="isEdit"
                                                     :showFileList="false"
                                                     :customStyle="true"
                                                     @onCompleted="handleImageUpload">
                                            <div style="text-align: center;width: 286px;">
                                                <el-button>
                                                    <i class="icon icon-upload-arrow"></i>
                                                    上传图片
                                                </el-button>
                                            </div>
                                        </file-upload>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">其他信息</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="车辆检修状态：" prop="fixState">
                                        <el-select v-model="form.fixState" :disabled="!isEdit" style="width: 100%">
                                            <el-option label="未检修" :value="0"></el-option>
                                            <el-option label="已检修" :value="1"></el-option>
                                            <el-option label="未知" :value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="车辆年检状态：" prop="checkState">
                                        <el-select v-model="form.checkState" :disabled="!isEdit" style="width: 100%">
                                            <el-option label="未年审" :value="0"></el-option>
                                            <el-option label="年审合格" :value="1"></el-option>
                                            <el-option label="年审不合格" :value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                    <el-form-item label="行驶总里程：" prop="total">
                                        <el-input v-model="form.total" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="车辆服务类型：" prop="carCommercialType">
                                        <el-select v-model="form.carCommercialType" :disabled="!isEdit"
                                                   style="width: 100%">
                                            <el-option label="网络预约出租汽车" :value="1"></el-option>
                                            <el-option label="巡游出租汽车" :value="2"></el-option>
                                            <el-option label="私人小客车合成" :value="3"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="9">
                                    <el-form-item label="发票打印设备序列号：" label-width="180px" prop="feePrintId">
                                        <el-input v-model="form.feePrintId" :maxLength="11"
                                                  :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位装置品牌：" label-width="180px" prop="gpsBrand">
                                        <el-input v-model="form.gpsBrand" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位装置型号：" label-width="180px" prop="gpsModel">
                                        <el-input v-model="form.gpsModel" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位装置IMEI：" label-width="180px" prop="gpsImei">
                                        <el-input v-model="form.gpsImei" :maxLength="11" :disabled="!isEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="卫星定位设备安装日期：" label-width="180px"
                                                  prop="gpsInstalDate">
                                        <el-date-picker type="date" v-model="form.gpsInstalDate" :disabled="!isEdit"
                                                        value-format="yyyy/MM/dd" style="width: 100%"></el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">绑定司机</span>
                                </p>
                                <table class="table">
                                    <tr class="table-title">
                                        <td>姓名</td>
                                        <td>手机号</td>
                                        <td>驾驶证号</td>
                                    </tr>
                                    <tr v-for="item in (form.userDriverInfoDtos||[])"
                                        :key="item.id">
                                        <td>{{ item.driverName }}</td>
                                        <td>{{ item.driverMobile }}</td>
                                        <td>{{ item.driverLicenseNumber }}</td>
                                    </tr>
                                </table>
                                <div style="padding: 10px;display: flex;align-items: center;margin-top: 10px">
                                    是否带车：
                                    <el-radio-group v-model="form.personalCar" :disabled="!isEdit">
                                        <el-radio label="0">否</el-radio>
                                        <el-radio label="1">是</el-radio>
                                    </el-radio-group>
                                </div>
                            </div>
                        </el-card>
                    </el-form>
                    <el-form label-position="right"
                             label-width="120px"
                             size="small" :model="netForm" :rules="ruleForm" ref="netRule">
                        <el-card class="box-card">
                            <p class="box-title">车辆网约车信息</p>
                            <div class="driver-box-card clear">
                                <p style="margin-top: 5px">
                                    <span class="label-title">状态信息</span>
                                </p>
                                <div class="verify-status" :class="formatStatus(netForm.carOperationAuditStatus).class">
                                    车辆网约信息：{{ formatStatus(netForm.carOperationAuditStatus).label }}
                                </div>
                                <div class="verify-status"
                                     :class="{'verify-default': netForm.carFlagStatus ==0,
                                     'verify-success': netForm.carFlagStatus ==1,
                                     'verify-refuse': netForm.carFlagStatus ==2}">
                                    车辆备案状态：{{
                                        netForm.carFlagStatus == 0 ? "未备案" : (netForm.carFlagStatus == 1 ? "已备案" :
                                            "备案失败")
                                    }}
                                </div>
                                <div style="float:right" v-if="!isNetFormEdit">
                                    <el-button v-if="netForm.carOperationAuditStatus!==2||isCapacityManager||isSupplier"
                                               type="success"
                                               size="small"
                                               class="verify-btn"
                                               @click="isNetFormEdit=true">编辑
                                    </el-button>
                                    <el-button class="verify-btn" type="primary" size="small" @click="verifyDialog(2)"
                                               v-if="netForm.carOperationAuditStatus==1||(netForm.carOperationAuditStatus==4&&userInfo.refType!=1)">
                                        审核
                                    </el-button>
                                    <el-button class="verify-btn"
                                               type="primary"
                                               size="small"
                                               :disabled="netForm.carOperationAuditStatus!=2||netForm.carAuditStatus!=2"
                                               @click="flagVerify()">备案
                                    </el-button>
                                </div>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">运输证</span>
                                </p>
                                <el-col :span="9">
                                    <el-form-item label="发证机构：" prop="transAgency">
                                        <el-input v-model="netForm.transAgency" :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="运输证字号：" prop="certificate">
                                        <el-input v-model="netForm.certificate " :disabled="!isNetFormEdit"></el-input>
                                    </el-form-item>
                                    <el-form-item label="有效期限：">
                                        <el-col :span="11">
                                            <el-form-item prop="transDateStart">
                                                <el-date-picker type="date" v-model="netForm.transDateStart"
                                                                :disabled="!isNetFormEdit" style="width: 100%;"
                                                                value-format="yyyy/MM/dd"></el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                        <el-col class="line" :span="2" style="text-align: center">-</el-col>
                                        <el-col :span="11">
                                            <el-form-item prop="transDateStop">
                                                <el-date-picker type="date" v-model="netForm.transDateStop"
                                                                :disabled="!isNetFormEdit" style="width: 100%;"
                                                                value-format="yyyy/MM/dd"></el-date-picker>
                                            </el-form-item>
                                        </el-col>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="运输证正面：">
                                        <div>
                                            <div v-if="netForm.transportCertificatePhoto" class="preview_img">
                                                <el-image lazy
                                                          style="width: 286px;height: 186px;"
                                                          :src="netForm.transportCertificatePhoto"
                                                          :preview-src-list="[netForm.transportCertificatePhoto]">
                                                </el-image>
                                            </div>
                                            <p v-else>暂无照片</p>
                                        </div>
                                        <file-upload id="transportCertificatePhoto"
                                                     v-if="isNetFormEdit"
                                                     :showFileList="false"
                                                     :customStyle="true"
                                                     @onCompleted="handleImageUpload">
                                            <div style="text-align: center;width: 286px;">
                                                <el-button>
                                                    <i class="icon icon-upload-arrow"></i>
                                                    上传图片
                                                </el-button>
                                            </div>
                                        </file-upload>

                                    </el-form-item>
                                </el-col>
                            </div>
                            <div class="driver-box-card clear">
                                <p>
                                    <span class="label-title">保险信息</span>
                                </p>
                                <table class="table">
                                    <tr class="table-title">
                                        <td style="width:80px;">保险类型</td>
                                        <td>保险公司名称</td>
                                        <td style="width:220px;">保单号</td>
                                        <td>保额(元)</td>
                                        <td>有效期限</td>
                                        <td>保单照片</td>
                                    </tr>
                                    <tr v-for="(item,index) in netForm.carInsuranceDtos" :key="item.id" class="insur">
                                        <td style="width:80px;">{{ item.insurType }}</td>
                                        <td>
                                            <el-form-item label-width="0"
                                                          :prop="'carInsuranceDtos.' + index + '.insurCompany'"
                                                          :rules="[{ validator: validateChart, trigger: 'blur' }]">
                                                <el-input v-model="item.insurCompany"
                                                          :disabled="!isNetFormEdit"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td style="width:220px;">
                                            <el-form-item label-width="0"
                                                          :prop="'carInsuranceDtos.' + index + '.insurNum'"
                                                          :rules="[{ validator: validateLettersOrNumber, trigger: 'blur' }]">
                                                <el-input v-model="item.insurNum" :disabled="!isNetFormEdit"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td>
                                            <el-form-item label-width="0"
                                                          :prop="'carInsuranceDtos.' + index + '.insurCount'"
                                                          :rules="[{ validator: validateNumberRequire, trigger: 'blur' }]">
                                                <el-input v-model="item.insurCount"
                                                          :disabled="!isNetFormEdit"></el-input>
                                            </el-form-item>
                                        </td>
                                        <td style="padding-left: 0;width: 340px">
                                            <el-col :span="11">
                                                <el-form-item label-width="0"
                                                              :prop="'carInsuranceDtos.' + index + '.insurEff'"
                                                              :rules="[{required: true, message: '请选择日期', trigger: 'change'}]">
                                                    <el-date-picker type="date" v-model="item.insurEff"
                                                                    :disabled="!isNetFormEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col class="line" :span="2" style="text-align: center;line-height: 32px">
                                                至
                                            </el-col>
                                            <el-col :span="11">
                                                <el-form-item label-width="0"
                                                              :prop="'carInsuranceDtos.' + index + '.insurExp'"
                                                              :rules="[{required: true, message: '请选择日期', trigger: 'change'}]">
                                                    <el-date-picker type="date" v-model="item.insurExp"
                                                                    :disabled="!isNetFormEdit" style="width: 100%;"
                                                                    value-format="yyyy/MM/dd"></el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <!--<el-date-picker-->
                                            <!--v-model="item.insurDate" @change="selectDate"-->
                                            <!--type="daterange" value-format="yyyy/MM/dd"-->
                                            <!--range-separator="至"-->
                                            <!--start-placeholder="开始月份"-->
                                            <!--end-placeholder="结束月份" :disabled="!isEdit">-->
                                            <!--</el-date-picker>-->
                                        </td>
                                        <td>
                                            <div class="preview_img" style="text-align: center;margin-top: 8px">
                                                <a href="javascript:void(0)" @click="goToPreviewImage(item.insurPhoto)">
                                                    <el-image
                                                        style="width: 25px; height: 35px"
                                                        :src="item.insurPhoto">
                                                        <div slot="error" class="image-slot">
                                                            <i class="el-icon-picture-outline"></i>
                                                        </div>
                                                    </el-image>
                                                </a>
                                            </div>
                                            <div v-if="isNetFormEdit" :disabled="!isNetFormEdit">
                                                <div class="excel-file-btns">
                                                    <a class="color-1989FA" href="javascript:void(0);">上传
                                                        <input class="upload" type="file"
                                                               @change="handleSelectFile(index)"
                                                               :disabled="!isNetFormEdit"
                                                               accept="image/*"/>
                                                    </a>
                                                </div>
                                            </div>

                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </el-card>
                    </el-form>
                </el-row>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont">
                <div style="text-align: right">
                    <el-row>
                        <div style="float: right;" v-if="isEdit||isNetFormEdit">
                            <span style="font-size: 14px;padding-right: 30px">正在编辑</span>
                            <el-button class="close-button edit-button" size="small" @click="close()">取消</el-button>
                            <el-button type="primary" class="edit-button" size="small" @click="save()">保存</el-button>
                        </div>
                        <!--<div style="line-height: 40px;float: right;margin-right: 20px" v-else>-->
                        <!--<el-button type="primary" class="edit-button" size="small" @click="isEdit=true">编辑模式-->
                        <!--</el-button>-->
                        <!--&lt;!&ndash;<el-radio-group v-model="isEdit" @change>&ndash;&gt;-->
                        <!--&lt;!&ndash;<el-radio :label="false">查看模式</el-radio>&ndash;&gt;-->
                        <!--&lt;!&ndash;<el-radio :label="true">编辑模式</el-radio>&ndash;&gt;-->
                        <!--&lt;!&ndash;</el-radio-group>&ndash;&gt;-->
                        <!--</div>-->
                    </el-row>
                </div>
            </div>
        </el-footer>
        <el-dialog
            :visible.sync="dialogVisible"
            width="30%"
            :before-close="handleClose">
            <div slot="title">
                <div v-if="!isRefuse">
                    <i class="el-icon-warning" size="22" style="color: #FAAD14"></i>
                    <span style="padding-left: 10px;font-weight: 700;font-size: 16px">确定通过审核吗？</span>
                </div>
                <div v-else>
                    <span style="font-weight: 700;font-size: 16px">请输入拒绝通过的原因：</span>
                </div>
            </div>
            <div v-if="!isRefuse">
                <p>此操作不可逆，请确认内容已被仔细审阅，没有遗漏。</p>
            </div>
            <div v-else>
                <el-form :model="refuseForm" ref="refuseForm" :rules="refuseRule">
                    <el-form-item prop="remarks">
                        <el-input v-model="refuseForm.remarks" type="textarea" :rows="3"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer" class="dialog-footer" v-if="!isRefuse">
                <el-button @click="isRefuse = true">不通过</el-button>
                <el-button type="primary" @click="successVerify()">通过</el-button>
            </div>
            <div v-else slot="footer" class="dialog-footer">
                <el-button @click="isRefuse = false">取消</el-button>
                <el-button type="primary" @click="failVerify()">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script type="text/ecmascript-6">
import {getCityData} from "@/urls/index";
import {getSeatModel} from "@/data/index";
import BrandModel from "@/components/brandModel/index";
import SeatModel from "@/components/seatModel/index";
import {vehicleColors, fuelTypes, engineDisplaces, plateColors} from '@/data/vehicleData/index'
import {
    validateChinese, validateChart, validateNumber,
    validateLettersOrNumber, validatePlate, getStatusInfo, validateNumberRequire
} from "../service"
import {up2QiNiu} from '@/common/js/up2QinNiu/index'
import SectionContent from "@/components/sectionContent/index.vue";
import FileUpload from '@/components/fileUpload'
import EditPopover from './EditPopover'
import DriverVehicleVerification from "@/www/pages/driverManage/components/driver-vehicle-verification.vue";
import SupplierSelect from "@/www/components/supplier-select/index.vue"

let date = null;
let initForm = {
    transArea: '上海市',
    supplierId: null,
    provincial: '沪',
    carNumberPlate: null,
    plateColor: plateColors[0],
    licenseCarType: null,
    carOwner: null,
    useCharacter: null,
    licenseCarModel: null,
    vin: null,
    engineId: null,
    carRegisterDate: date,
    issueDate: date,
    seats: 4,
    nextFixDate: date,
    carBrandModel: [],
    carType: 1,
    carColor: vehicleColors[0],
    fuelType: fuelTypes[0],
    engineDisplace: engineDisplaces[0],
    wheelbase: null,
    carLicenseFacePhoto: null,
    carLicenseBackPhoto: null,
    carPhoto: null,
    fixState: 1,
    checkState: 1,
    total: 30000,
    carCommercialType: 1,
    feePrintId: null,
    gpsBrand: '汇星行',
    gpsModel: 'VGC-8',
    gpsImei: '1',
    gpsInstalDate: date,
    personalCar: 0
}
let initNetForm = {
    transAgency: null,
    certificate: null,
    transDateStart: date,
    transDateStop: date,
    transportCertificatePhoto: null
}
const createCarInsurance = () => ['交强险', '商业险', '意外险'].map((item) => ({
    insurType: item,
    insurCompany: null,
    insurNum: null,
    insurCount: null,
    insurEff: date,
    insurExp: date,
    insurPhoto: null,
    carNumberPlate: null
}))

export default {
    name: 'vehicleDetail',
    //定义模版数据
    data() {
        return {
            form: {
                ...initForm
            },
            netForm: {
                ...initNetForm,
                carInsuranceDtos: createCarInsurance()
            },
            serverCityList: [],
            brandModelList: [],
            isEdit: false,
            isNetFormEdit: false,
            vehicleColors,
            fuelTypes,
            engineDisplaces,
            ruleForm: {
                carNumberPlate: [{validator: validatePlate, trigger: 'blur'}],
                plateColor: [{required: true, message: '请选择颜色', trigger: 'change'}],
                supplierId: [{required: true, message: '请选择所属供应商', trigger: 'change'}],
                licenseCarType: [{validator: validateChinese, trigger: 'blur'}],
                carOwner: [{required: true, message: '请输入车辆所有人', trigger: 'blur'}],
                useCharacter: [{validator: validateChart, trigger: 'blur'}],
                // licenseCarModel: [{validator: validateChart, trigger: 'blur'}],
                vin: [{validator: validateLettersOrNumber, trigger: 'blur'}],
                engineId: [{required: true, trigger: 'blur'}],
                carRegisterDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                issueDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                seats: [{validator: validateNumber, trigger: 'blur'}],
                nextFixDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                carBrandModel: [{required: true, message: '请选择品牌型号', trigger: 'change'}],
                carColor: [{required: true, message: '请选择颜色', trigger: 'change'}],
                fuelType: [{required: true, message: '请选择燃油类型', trigger: 'change'}],
                engineDisplace: [{required: true, message: '请选择发动机排量', trigger: 'change'}],
                fixState: [{required: true, message: '请选择车辆检修状态', trigger: 'change'}],
                checkState: [{required: true, message: '请选择车辆年检状态', trigger: 'change'}],
                total: [{validator: validateNumber, trigger: 'blur'}],
                carCommercialType: [{required: true, message: '请选择车辆服务类型', trigger: 'change'}],
                feePrintId: [{validator: validateNumber, trigger: 'blur'}],
                gpsBrand: [{required: true, message: '请输入内容', trigger: 'blur'}],
                gpsModel: [{required: true, message: '请输入内容', trigger: 'blur'}],
                gpsImei: [{required: true, message: '请输入内容', trigger: 'blur'}],
                gpsInstalDate: [{required: true, message: '请选择日期', trigger: 'change'}],
                transAgency: [{validator: validateChart, trigger: 'blur'}],
                certificate: [{validator: validateChart, trigger: 'blur'}],
                transDateStart: [{required: true, message: '请选择日期', trigger: 'change'}],
                transDateStop: [{required: true, message: '请选择日期', trigger: 'change'}],
            },
            dialogVisible: false,
            dialogIndex: 0,
            isRefuse: false,
            refuseForm: {
                remarks: null
            },
            refuseRule: {
                remarks: [{required: true, message: '请输入拒绝理由', trigger: 'blur'}]
            },
            validateChart,
            validateNumber,
            validateLettersOrNumber,
            validateNumberRequire
        };
    },
    components: {
        DriverVehicleVerification,
        SectionContent,
        BrandModel,
        SeatModel,
        FileUpload,
        EditPopover,
        SupplierSelect
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        isCapacityManager() {
            return ['总部运力管理员', '总部管理员', '总部超级管理员'].includes(this.$store.state.loginUserInfo.roleName)
        },
        isSupplier() {
            return this.$store.state.loginUserInfo.userType === '1'
        }
    },
    //主件被加载完成
    mounted: function () {
        this.getServerCity();
    },
    activated() {
        let id = this.$route.params.id;
        if (this._vehicleId === id)
            return;
        this._vehicleId = id;
        this.isEdit = false;
        this.getDetail();
    },
    //定义事件方法
    methods: {
        async getServerCity() {
            const ret = await getCityData(this, "2");
            if (ret) this.serverCityList = ret;
        },
        async getDetail() {
            let res = await this.http(
                `/galaxyDriverApproveApi/getCarInfoDetail/${this._vehicleId}`
            );
            if (res.success) {
                const data = JSON.parse(JSON.stringify(res.data));
                this.form = {...data};
                if (data.carOperationAuditStatus !== 0 && data.carOperationAuditStatus !== 5)
                    this.netForm = {...data};
                if (!this.netForm.carInsuranceDtos || this.netForm.carInsuranceDtos.length === 0)
                    this.netForm.carInsuranceDtos = createCarInsurance();
                this.resetForm = {...data};
                this.form.supplierId = data.carSupplierId
                this.form.carType = parseInt(this.form.carType);
                this.form.carBrandModel = [this.form.carBrand, this.form.carModel];
                this.selectBrand({carType: this.form.carType})
                this.$refs.ruleForm.clearValidate();
            }
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        selectBrand(val) {
            this.form.carType = parseInt(val.carType);
            const carModel = this.formatCarModel(val.carType);
            this.form.wheelbase = carModel.wheelbase || 0;
        },
        close() {
            this.$confirm('是否取消编辑?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const resetForm = JSON.parse(JSON.stringify(this.resetForm));
                this.form = resetForm;
                this.netForm = resetForm;
                this.form.carType = parseInt(this.form.carType);
                this.form.carBrandModel = [this.form.carBrand, this.form.carModel];
                this.selectBrand({carType: this.form.carType})
                this.isEdit = false;
                this.isNetFormEdit = false;
            }).catch(() => {
            });
        },
        save() {
            let formName = 'ruleForm';
            if (this.isNetFormEdit)
                formName = 'netRule'
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submit();
                } else {
                    this.messageError("信息不全,请填写完整")
                }
            })
        },
        async submit(f) {
            if (f)
                this.form = f;
            let form = {
                carAuditStatus: this.form.carAuditStatus == 3 ? 1 : this.form.carAuditStatus,
                carBrand: this.form.carBrandModel[0],
                carColor: this.form.carColor,
                carLicenseBackPhoto: this.form.carLicenseBackPhoto,
                carLicenseFacePhoto: this.form.carLicenseFacePhoto,
                carModel: this.form.carBrandModel[1],
                carNumberPlate: this.form.carNumberPlate,
                carOwner: this.form.carOwner,
                carRegisterDate: this.form.carRegisterDate,
                carType: this.form.carType,
                driverMobile: this.form.driverMobile,
                driverName: this.form.driverName,
                engineDisplace: this.form.engineDisplace,
                engineId: this.form.engineId,
                fuelType: this.form.fuelType,
                id: this.form.id,
                issueDate: this.form.issueDate,
                licenseCarModel: this.form.licenseCarModel,
                licenseCarType: this.form.licenseCarType,
                loginUserId: this.form.loginUserId,
                nextFixDate: this.form.nextFixDate,
                plateColor: this.form.plateColor,
                seats: this.form.seats,
                supplierId: this.form.supplierId,
                supplierName: this.form.supplierName,
                transArea: this.form.transArea,
                useCharacter: this.form.useCharacter,
                vin: this.form.vin,
                wheelbase: this.form.wheelbase,
                carPhoto: this.form.carPhoto,
                personalCar: this.form.personalCar
            }
            this.netForm.carInsuranceDtos.forEach((item) => {
                item.carNumberPlate = this.form.carNumberPlate
            })
            form.carInfoNetCarUpdateReqDto = {
                accidentInsurancePhoto: this.netForm.accidentInsurancePhoto,
                carCommercialType: this.form.carCommercialType,
                carOperationAuditStatus: 1,
                checkState: this.form.checkState,
                compulsoryInsurancePhoto: this.form.compulsoryInsurancePhoto,
                feePrintId: this.form.feePrintId,
                fixState: this.form.fixState,
                gpsBrand: this.form.gpsBrand,
                gpsImei: this.form.gpsImei,
                gpsInstalDate: this.form.gpsInstalDate,
                gpsModel: this.form.gpsModel,
                id: this.form.netCarId,
                loginUserId: this.form.loginUserId,
                total: this.form.total,
                certificate: this.netForm.certificate,
                transAgency: this.netForm.transAgency,
                carInsuranceDtos: this.netForm.carInsuranceDtos,
                transDateStart: this.netForm.transDateStart,
                transDateStop: this.netForm.transDateStop,
                transportCertificatePhoto: this.netForm.transportCertificatePhoto,
                vechileInsurancePhoto: this.form.vechileInsurancePhoto,
            }
            this.loadingShow();
            let res = await this.http("/galaxyDriverApproveApi/doCarInfoApprovalUpdate", form, "POST");
            this.loadingHide();
            if (res.success) {
                this.isEdit = false;
                this.isNetFormEdit = false;
                this.getDetail();
                this.$message.success("修改成功")
                return true
            }
        },
        formatStatus(status) {
            let info = getStatusInfo(status || 0);
            return info;
        },
        async verify(status) {
            let form = {
                loginUserId: this.form.loginUserId,
                id: this.form.id,
                carAuditStatus: status
            }
            if (status == 3) form.remarks = this.refuseForm.remarks;
            this.loadingShow()
            let res = await this.http("/galaxyDriverApproveApi/doCarInfoApproval", form, "POST");
            this.loadingHide();
            this.dialogVisible = false;
            this.isRefuse = false;
            if (res.success) {
                this.$message.success("操作成功")
                this.getDetail()
            } else {
                this.$message.success("操作失败")
            }
        },
        async flagVerify() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    this.$confirm(this.netForm.carFlagStatus === 1 ? '该车辆已备案成功，是否重新备案？' : '是否备案?', '提示', {
                        confirmButtonText: this.netForm.carFlagStatus === 1 ? '重新备案' : '备案',
                        cancelButtonText: '取消'
                    }).then(async () => {
                        let form = {
                            id: this.form.id,
                            carNumberPlate: this.form.carNumberPlate,
                            driverFlagStatus: 1

                        }
                        this.loadingShow();
                        let res = await this.http("/galaxyDriverApproveApi/doCarInfoNetCarRecord", form, "POST");
                        console.log(res);
                        if (res.success) {
                            this.refuseForm.remarks = null;
                            this.$message.success("操作成功")
                            await this.getDetail()
                        } else {
                            this.$message.error("操作失败")
                        }
                        this.loadingHide();
                    }).catch(() => {
                    });
                }
            })
        },
        async netVerify(status) {
            let form = {
                loginUserId: this.form.loginUserId,
                id: this.form.id,
                carOperationAuditStatus: status,
            }
            if (status == 3) form.remarks = this.refuseForm.remarks;
            this.loadingShow();
            let res = await this.http("/galaxyDriverApproveApi/doCarNetCarApproval", form, "POST");
            this.loadingHide();
            this.dialogVisible = false;
            this.isRefuse = false;
            if (res.success) {
                this.refuseForm.remarks = null;
                this.$message.success("操作成功")
                this.getDetail()
            } else {
                this.$message.error("操作失败")
            }
        },
        verifyDialog(index) {
            this.dialogVisible = true;
            this.dialogIndex = index;

        },

        handleInfoExport() {
            this.$router.push(`/infoPrint/1?ids=${this._vehicleId}`);
        },
        handleClose(done) {
            done();
        },
        successVerify() {
            let name = this.dialogIndex === 2 ? "netRule" : "ruleForm";
            this.$refs[name].validate(async (valid) => {
                if (valid) {
                    let status = 4;
                    if (this.dialogIndex == 1) {
                        if (this.form.carAuditStatus == 4 || this.userInfo.refType != 1) status = 2;
                        this.verify(status);
                    }
                    if (this.dialogIndex == 2) {
                        if (this.form.carOperationAuditStatus == 4 || this.userInfo.refType != 1) status = 2;
                        this.netVerify(status);
                    }
                } else {
                    this.dialogVisible = false;
                    this.messageError("信息不全,请填写完整")
                }
            })
        },
        failVerify() {
            this.$refs.refuseForm.validate(async (valid) => {
                if (valid) {
                    if (this.dialogIndex == 1) this.verify(3);
                    if (this.dialogIndex == 2) this.netVerify(3);
                }

            })
        },
        async handleSelectFile(index) {
            this.loading = true;
            const file = event.currentTarget.files[0];
            let extend = ".jpg";
            if (file.name) {
                extend = file.name.split('.')[1];
            }
            let imgName = (+new Date()) + '.' + extend;
            try {
                let url = await up2QiNiu(this, imgName, file);
                if (url) {
                    let data = this.netForm.carInsuranceDtos[index];
                    data.insurPhoto = url;
                    this.$set(this.netForm.carInsuranceDtos, index, data);
                } else {
                    this.loading = false;
                    this.$message.error("抱歉文件上传失败，请重新上传!")
                }
            } catch (e) {
                this.loading = false;
                this.$message.error("抱歉文件上传失败，请重新上传!")
            }

        },
        selectDate(val) {
            console.log(val);
        },
        /**
         * 预览图片
         */
        goToPreviewImage(url) {
            const uri = `#/previewImage/${encodeURIComponent(url)}`
            window.open(uri)
        },
        //OCR识别
        async ocr(photoUrl, side) {
            const ret = await this.http('/galaxyDriverApproveApi/doDrivingLicenseDistinguishOcr', {
                photoUrl,
                side
            }, 'POST');
            if (ret.success && ret.data) {
                let res = ret.data;

                let form = {
                    ...this.form,
                    ...res
                };
                for (let key in form) {
                    if (key.indexOf('Date') !== -1 && new Date(form[key]).toString() === 'Invalid Date') {
                        form[key] = null;
                    }
                }
                if (res['carNumberPlate']) {
                    form.carNumberPlate = res['carNumberPlate'].substr(1, res['carNumberPlate'].length - 1);
                    form.provincial = res['carNumberPlate'].substr(0, 1)
                }
                this.form = form;

            } else
                return false;
        },
        //文件上传完成
        handleImageUpload({id, url}) {
            switch (id) {
                case 'carLicenseFacePhoto':
                    this.form[id] = url;
                    this.ocr(url, 0)
                    break;
                case 'carLicenseBackPhoto':
                    this.form[id] = url;
                    this.ocr(url, 1)
                    break;
                case 'carPhoto':
                    this.form[id] = url;
                    break;
                case 'transportCertificatePhoto':
                    this.netForm[id] = url;
                    break;
            }
            this.$forceUpdate();
        },
    },
    //监听模版变量
    watch: {
//            'form.carNumberPlate': function (n) {
//                if (n) {
//                    this.form.carNumberPlate = n.toUpperCase();
//                    this.form.plateColor = n.length === 7 ? plateColors[1] : plateColors[0]
//                }
//            },
    }
};
</script>
