<style lang="scss" scoped>

</style>
<template>
    <el-dialog title="添加黑名单"
               width="600px"
               custom-class="el-dialog-middle-center"
               class="mod-driver-confirm"
               :visible.sync="visible">
        <el-form ref="form" :model="form" :rules="rules" v-loading="loading">
            <el-form-item prop="type" label="类型：" required>
                <el-radio-group v-model="form.type">
                    <el-radio v-for="vm in types" :key="vm" :value="vm" :label="vm"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item prop="cityId" label="城市">
                <SelectCity ref="selectCity" size="normal" :is-border="false"
                            @update:city="handleCityChange"></SelectCity>
            </el-form-item>
            <el-form-item prop="id" :label="form.type==='司机'?'身份证号':'车牌号'">
                <el-input v-model='form.id' :placeholder="'请输入'+(form.type==='司机'?'身份证号':'车牌号')"></el-input>
            </el-form-item>
            <el-form-item prop="reason" label="原因">
                <el-input type="textarea" placeholder="请输入原因" v-model="form.reason"></el-input>
            </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
            <el-button size="small" :loading="loading" @click="handleCancel">取 消</el-button>
            <el-button size="small" type="primary" :loading="loading" @click="handleSubmit">添 加</el-button>
        </div>
    </el-dialog>

</template>

<script type="text/ecmascript-6">
import {isCarNumberPlate, isIDCardNumber} from "@/common/js/Utils";
import {addBlackListApi} from "@/www/urls/driverManage";
import SelectCity from "@/components/selectCity/index.vue";

export default {
    //定义模版数据
    data() {
        return {
            loading: false,
            visible: false,
            types: ['司机', '车辆'],
            form: {
                type: '司机',
                idCardNumber: '',
                reason: '',
                carNumberPlate: '',
                id: '',
                cityId: ''
            },
            rules: {
                id: [{
                    required: true,
                    validator: (rule, value, callback) => {
                        if (!value)
                            return callback('请输入' + (this.form.type === '司机' ? '身份证号' : '车牌号'))
                        if (this.form.type === '司机') {
                            if (!isIDCardNumber(value))
                                return callback('身份证号格式错误')
                        } else {
                            if (!isCarNumberPlate(value))
                                return callback('车牌号格式错误')
                        }
                        callback();
                    },
                    trigger: 'blur'
                }],
                reason: [{
                    required: true,
                    message: '请输入原因',
                    trigger: 'blur'
                }],
                cityId: [{
                    required: true,
                    message: '请选择城市',
                    trigger: 'change'
                }]
            }
        }
    },
    components: {
        SelectCity
    },
    methods: {
        open() {
            this.reset();
            this.visible = true
        },
        reset() {
            this.form.id = ''
            this.form.type = '司机'
            this.form.reason = ''
            this.form.cityId = ''
            this.form.cityName = ''
            this.$refs.selectCity && this.$refs.selectCity.clear()
            this.$refs.form && this.$refs.form.clearValidate()
        },
        handleCancel() {
            this.visible = false
            this.$nextTick(() => {
                this.reset();
            })
        },
        async handleSubmit() {
            try {
                await this.$refs['form'].validate()
                this.loading = true
                const ret = await addBlackListApi({
                    idCardNumber: this.form.type === '司机' ? this.form.id : undefined,
                    carNumberPlate: this.form.type === '车辆' ? this.form.id : undefined,
                    reason: this.form.reason,
                    cityId: this.form.cityId,
                    cityName: this.form.cityName,
                })
                if (ret.success) {
                    this.$message.success('添加成功')
                    this.visible = false
                    this.$emit('onRefresh')
                    this.$nextTick(() => {
                        this.reset();
                    })
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loading = false
            }
        },
        handleCityChange(city) {
            this.form.cityId = city.shortCode
            this.form.cityName = city.city
        }

    },
    //监听模版变量
    watch: {}

}
</script>

