/**
* Created by hansxing on 2019/4/10.
*/
<style lang="scss" scoped>
.alert-ct {
    padding: 0 28px 10px 28px;
    background-color: #f8f8f8;
    margin-top: -10px;
}

.product-list {
    .product-list-inline {
        .el-form-item {
            width: 135px;
        }
    }

    .product-check-list {
        margin-top: 5px;

        .check-list-title {
            font-size: 14px;
            margin-right: 20px;
        }

        .check-list-group {
            display: inline-block;
        }
    }

    .product-list-table {
        background: #fff;
        margin-top: 10px;
        /*padding: 0 20px;*/
    }

    .search-show-product {
        display: inline;
    }
}

.product-list /deep/ {
    .table-info {
        .el-table__fixed-right {
            right: 10px !important;
        }

        .el-table__fixed-right-patch {
            width: 10px !important;
        }
    }
}
</style>
<style lang="scss">
.upper-lower-lines-dialog {
    top: 50%;
    margin: auto;

    .el-dialog__header {
        display: none;
    }

    .el-dialog__body {
        text-align: center;
        font-size: 16px;
    }

    .el-dialog__footer {
        padding: 0 20px 20px 20px;
        text-align: center;
    }
}

.product-list-table {
    .operation-btn {
        .el-button {
            padding: 0;
            margin: 5px;
            margin-left: 0;
            float: left;
        }
    }
}

.common-list-search .list-search-before {
    .label-pub-w {
        &.el-form-item.el-form-item--small {
            width: auto;
            /*min-width: 320px;*/
        }

        .el-form-item__content {
            width: auto;
        }
    }
}

.driver-score-popup {
    .driver-score-popup_head {
        border-bottom: 2px solid #F0F2F5;
        padding-top: 5px;
        padding-bottom: 15px;

        .icon-evaluate-star {
            font-size: 30px;

        }

        .el-rate__text {
            font-size: 18px;
        }

        h4 {
            color: #3C3C3C;
            font-size: 18px;
            font-weight: 600
        }
    }

    .driver-score-popup_rate {
        .el-rate__icon {
            font-size: 38px;
            color: #DDDDDD;
        }
    }

}
</style>
<template>
    <div>
        <div class="alert-ct">
            <el-alert v-if="cancelAccountStatusCount" type="warning" show-icon closable>
                <span slot="title">
                    有{{ cancelAccountStatusCount }}位司机注销待确认，点击此处
                    <span class="color-primary" style="cursor: pointer"
                          @click="handleGetCancelAccountStatus">查看>></span>
                </span>
            </el-alert>
        </div>
        <el-main class="com-eTrip-section section-has-footer">
            <section-content class="product-list">
                <div style="background-color: #fff;padding: 0 10px">
                    <!--                <com-list-search>-->
                    <!--                    <div slot="list-search-before">-->
                    <el-form :inline="true" :model="form" size="small" label-position="left">
                        <el-form-item label="司机基本信息" label-width="100px" class="label-pub-w">
                            <el-radio-group v-model="form.driverAuditStatus">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="1">初审中{{ driverFirstCount }}</el-radio-button>
                                <el-radio-button label="4">复审中{{ driverSecondCount }}</el-radio-button>
                                <el-radio-button label="2">已通过</el-radio-button>
                                <el-radio-button label="3">未通过</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="备案状态" label-width="70px" class="label-pub-w">
                            <el-radio-group v-model="form.driverFlagStatus">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="0">未备案</el-radio-button>
                                <el-radio-button label="1">已备案</el-radio-button>
                                <el-radio-button label="2">备案失败</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="注销状态" label-width="70px" class="label-pub-w">
                            <el-radio-group v-model="form.cancelAccountStatus">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="2">待确认{{ cancelAccountStatusCount }}</el-radio-button>
                                <el-radio-button label="1">已注销</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="司机网约信息" label-width="100px" class="label-pub-w">
                            <el-radio-group v-model="form.driverCertificateAuditStatus">
                                <el-radio-button label>全部</el-radio-button>
                                <el-radio-button label="0">未提交</el-radio-button>
                                <el-radio-button label="1">初审中{{ driverNetFirstCount }}</el-radio-button>
                                <el-radio-button label="4">复审中{{ driverNetSecondCount }}</el-radio-button>
                                <el-radio-button label="2">已通过</el-radio-button>
                                <el-radio-button label="3">未通过</el-radio-button>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="司机是否带车" label-width="98px" class="label-pub-w">
                            <el-radio-group v-model="form.personalCar">
                                <el-radio-button :label="null">全部</el-radio-button>
                                <el-radio-button :label="1">是</el-radio-button>
                                <el-radio-button :label="0">否</el-radio-button>
                            </el-radio-group>
                        </el-form-item>

                        <el-form-item label>
                            <seat-model v-model="form.carType" placeholder="车型" is-all></seat-model>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="form.cityId" filterable placeholder="填写城市">
                                <el-option
                                    v-for="city in serverCityList"
                                    :key="city.shortCode"
                                    :label="city.name"
                                    :value="city.shortCode"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-select v-model="form.isValid" placeholder="是否可用">
                                <el-option label="不可用" value="0"></el-option>
                                <el-option label="可用" value="1"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.driverName" placeholder="姓名"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.driverMobile" placeholder="手机号"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-autocomplete
                                v-model="form.carNumberPlate"
                                :fetch-suggestions="querySearcPlate"
                                placeholder="绑定车辆"
                                :trigger-on-focus="false"
                                clearable
                                @select="handleSelectPlate"
                            >
                                <template slot-scope="{ item }">
                                    <div class="list-pop-bot">
                                        <span>{{ item.carNumberPlate }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                            <!--<el-input v-model="form.carNumberPlate" placeholder="车牌号"></el-input>-->
                        </el-form-item>
                        <el-form-item v-if="userInfo.userType!=1"
                                      style="width: 250px">
                            <el-autocomplete style="width: 250px"
                                             v-model="supplierName"
                                             :fetch-suggestions="querySearchSupplier"
                                             placeholder="供应商"
                                             :trigger-on-focus="false"
                                             clearable
                                             @select="handleSelect"
                            >
                                <template slot-scope="{ item }">
                                    <div class="list-pop-bot">
                                        <span>{{ item.supplierName }}</span>
                                    </div>
                                </template>
                            </el-autocomplete>
                        </el-form-item>
                        <el-form-item>
                            <fleet-name-search-input ref="fleetNameSearch"
                                                     @update:getSelectFleetName="getSelectFleetName">
                            </fleet-name-search-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.driverLicenseNumber" placeholder="身份证号"/>
                        </el-form-item>
                        <el-form-item>
                            <el-date-picker size="small" style="width: 240px" v-model="form.createOrderTime"
                                            type="daterange"
                                            range-separator="至"
                                            start-placeholder="注册起始日期"
                                            end-placeholder="注册截止日期">
                            </el-date-picker>
                        </el-form-item>
                        <el-form-item style="float: right">
                            <el-button @click="resetClick()">重置</el-button>
                            <el-button type="primary" @click="searchClick()">搜索</el-button>
                            <el-button @click="showSelection=true" v-if="!showSelection">台账批量打印</el-button>
                            <el-button type="warning" v-if="showSelection" @click="handleBatchPrint">确定批量打印
                            </el-button>
                            <el-button plain type="warning" v-if="showSelection" @click="showSelection=false">
                                取消批量打印
                            </el-button>
                            <el-button @click="handlePrintAll()" v-if="!showSelection">打印全部</el-button>
                            <el-button type="success" v-if="showApplyExport" :loading="applyExportLoading"
                                       @click="handleApplyExport">申请导出
                            </el-button>
                        </el-form-item>
                    </el-form>
                    <!--                    </div>-->
                    <!--                    <div slot="list-search-after" class="list-search-after_sec">-->
                    <!--                        <el-button @click="resetClick()">重置</el-button>-->
                    <!--                        <el-button type="primary" @click="searchClick()">搜索</el-button>-->
                    <!--                        <el-button>打印全部</el-button>-->
                    <!--                    </div>-->
                    <!--                </com-list-search>-->
                </div>
                <div class="product-list-table el-table_border_none">
                    <el-table
                        type="index"
                        class="table-info"
                        border
                        :data="driverList"
                        v-loading="listLoading"
                        @selection-change="handleSelectionChange"
                        style="width: 100%"
                    >
                        <el-table-column v-if="showSelection" type="selection" width="55"></el-table-column>
                        <el-table-column prop="driverName" label="姓名" width="110"></el-table-column>
                        <el-table-column prop="driverMobile" width="120" label="手机号"></el-table-column>
                        <el-table-column prop="cityName" label="营运城市" width="110"></el-table-column>
                        <el-table-column prop="supplierName" label="供应商" min-width="120"></el-table-column>
                        <el-table-column label="是否带车" width="60">
                            <template slot-scope="scope">
                                <p>
                                    {{ scope.row.personalCar === 0 ? '否' : '是' }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fleetName" label="车队" width="120"></el-table-column>
                        <el-table-column prop="carNumberPlate" label="绑定车辆" width="120"></el-table-column>
                        <el-table-column label="司机基本信息" width="70">
                            <template slot-scope="scope">
                                <p>
                                    <span :style="{color:scope.row.driverAuditStatus==3?'#FF3B30':''}">
                                        {{ formatStatus(scope.row.driverAuditStatus).label }}
                                    </span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="司机网约信息" width="70">
                            <template slot-scope="scope">
                                <p>
                                    <span :style="{color:scope.row.driverCertificateAuditStatus==3?'#FF3B30':''}">
                                        {{ formatStatus(scope.row.driverCertificateAuditStatus).label }}
                                    </span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="备案状态" width="80">
                            <template slot-scope="scope">
                                <!--scope.row.-->
                                <p>
                                    <span v-if="scope.row.driverFlagStatus==0">未备案</span>
                                    <span v-if="scope.row.driverFlagStatus==1">已备案</span>
                                    <span v-if="scope.row.driverFlagStatus==2">备案失败</span>
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column label="是否可用" width="60" align="center">
                            <template slot-scope="scope">
                                <p class="font_s20">
                  <span style="color: #FF3B30">
                    <i class="el-icon-close" v-if="scope.row.isValid==0||scope.row.cancelAccountStatus"></i>
                  </span>
                                    <span style="color: #2FCF1B">
                    <i class="el-icon-check" v-if="scope.row.isValid==1&&!scope.row.cancelAccountStatus"></i>
                  </span>
                                </p>
                                <span style="font-size: 12px" v-if="[1,2].includes(scope.row.cancelAccountStatus)"
                                      :class="scope.row.cancelAccountStatus===2?'color-waring':'color-danger'">
                                    {{ scope.row.cancelAccountStatus === 2 ? '注销待审核' : '已注销' }}
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <div class="operation-btn clear">
                                    <el-button type="text" @click="goEdit(scope.row)">查看</el-button>
                                    <el-button type="text" @click="showLog(scope.row)">日志</el-button>
                                    <template v-if="!scope.row.cancelAccountStatus">
                                        <el-button type="text"
                                                   @click="handleDriverChangeSupplier(scope.row)"
                                                   v-if="['0','2'].includes(userInfo.userType)">
                                            转会
                                        </el-button>
                                        <el-button
                                            style="color:#FF3B30"
                                            @click="enable(scope.$index)"
                                            type="text"
                                            v-if="scope.row.isValid == '1'&&scope.row.driverAuditStatus==2"
                                        >停用
                                        </el-button>
                                        <el-button
                                            @click="enable(scope.$index, true)"
                                            type="text"
                                            v-if="scope.row.isValid == '0'&&scope.row.driverAuditStatus==2&&userInfo.userType!=1"
                                        >启用
                                        </el-button>
                                        <el-popconfirm title="这是一段内容确定删除吗？"
                                                       @confirm="handleDeleteDriver(scope.row)"
                                                       v-if="userInfo.userType==='0'&&!scope.row.driverStatus&&!scope.row.driverAuditStatus&&!scope.row.carOperationAuditStatus&&!scope.row.driverCertificateAuditStatus&&!scope.row.carInfoId ">
                                            <el-button type="text" style="color:#FF3B30" slot="reference">删除
                                            </el-button>
                                        </el-popconfirm>
                                        <el-popover
                                            placement="bottom-end"
                                            width="380"
                                            trigger="click">
                                            <div class="driver-score-popup">
                                                <div class="driver-score-popup_head" style="text-align: center">
                                                    <h4>900游优秀司机</h4>
                                                    <div style="height: 38px">
                                                        <el-rate
                                                            class="driver-score-popup_rate"
                                                            v-model="driverAverage"
                                                            disabled
                                                            show-score
                                                            void-color="#DDDDDD"
                                                            disabled-void-color="#DDDDDD"
                                                            :icon-classes="['icon icon-evaluate-star', 'icon icon-evaluate-star','icon icon-evaluate-star']"
                                                            disabled-void-icon-class="icon icon-evaluate-star"
                                                            void-icon-class="icon icon-evaluate-star"
                                                            text-color="#3C3C3C"
                                                            score-template="{value}分">
                                                        </el-rate>
                                                    </div>
                                                </div>
                                                <div
                                                    style="padding: 15px;height: 220px;overflow: hidden;overflow-y: scroll">
                                                    <ul v-if="evaluationListInfoData && evaluationListInfoData.length > 0">
                                                        <li style="padding: 10px 0;border-bottom: 1px solid #F2F2F2"
                                                            v-for="(evaluationItem, idx) in evaluationListInfoData"
                                                            :key="idx">
                                                            <p>
                                                                {{
                                                                    evaluationItem.createTime | dateCus('yyyy.MM.dd hh:mm')
                                                                }}</p>
                                                            <el-rate
                                                                v-model="evaluationItem.evaluationLevel"
                                                                disabled
                                                                show-score
                                                                void-color="#DDDDDD"
                                                                disabled-void-color="#DDDDDD"
                                                                :icon-classes="['icon icon-evaluate-star', 'icon icon-evaluate-star','icon icon-evaluate-star']"
                                                                disabled-void-icon-class="icon icon-evaluate-star"
                                                                void-icon-class="icon icon-evaluate-star"
                                                                score-template="">
                                                            </el-rate>
                                                            <div style="font-size: 14px;color: #3C3C3C">
                                                                {{ evaluationItem.evaluation }}
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <div v-else>
                                                        暂无用户评价
                                                    </div>
                                                </div>

                                            </div>
                                            <el-button type="text" slot="reference" @click="handleEvaluate(scope.row)">
                                                评价
                                            </el-button>
                                        </el-popover>
                                        <el-button v-if="scope.row.personalCar===0&&!scope.row.carNumberPlate"
                                                   type="text"
                                                   @click="handleVehicleBindingDialog(scope.row,1)">车辆绑定
                                        </el-button>
                                        <el-button
                                            v-if="scope.row.personalCar===0&&scope.row.carNumberPlate"
                                            type="text"
                                            style="color: #FF3B30;"
                                            @click="handleVehicleBindingDialog(scope.row)">解绑车辆
                                        </el-button>

                                        <LoginLogPopover v-if="scope.row.cityName==='三亚市'" :driverInfo="scope.row"/>
<!--                                        <EpidemicPreventionInspectionPopover v-if="scope.row.cityName==='上海市'"-->
<!--                                                                             :driverInfo="scope.row"/>-->
                                        <el-button type="text" class="color-waring"
                                                   v-if="scope.row.cancelAccountStatus===0&&['0'].includes(userInfo.refType)"
                                                   @click="handleApplyCancellation(scope.row)">申请注销
                                        </el-button>
                                    </template>
                                    <el-button type="text" class="color-waring"
                                               v-if="scope.row.cancelAccountStatus===2&&['0'].includes(userInfo.refType)"
                                               @click="handleCancelCancellation(scope.row)">撤回注销
                                    </el-button>
                                    <el-button type="text" class="color-danger"
                                               v-if="scope.row.cancelAccountStatus===2&&['1','2'].includes(userInfo.refType)"
                                               @click="handleConfirmCancellation(scope.row)">确认注销
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </section-content>
        </el-main>
        <el-footer class="com-eTrip-footer" height="64px">
            <div class="eTrip-footer-cont table-page-number">
                <div class="block">
                    <el-pagination
                        @current-change="pageChange"
                        :current-page.sync="pageData.currentPage"
                        :page-size="pageData.pageSize"
                        layout="total, prev, pager, next"
                        :total="pageData.totalPage"
                    ></el-pagination>
                </div>
            </div>
        </el-footer>
        <el-dialog :visible.sync="dialogTableVisible">
            <el-table :data="logList">
                <el-table-column label="操作人" width="250">
                    <template slot-scope="scope">
                        {{
                            `${scope.row.operateName}${scope.row.operateMobile ? ('(' + scope.row.operateMobile + ')') :
                                ''}`
                        }}
                    </template>
                </el-table-column>
                <el-table-column label="操作时间" width="200">
                    <template slot-scope="scope">{{ scope.row.operateTime | dateCus('yyyy-MM-dd hh:mm:ss') }}</template>
                </el-table-column>
                <el-table-column property="operateContent" label="内容"></el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog :visible.sync="vehicleBindingDialog.show" title="车辆绑定" width="856px">
            <vehicle-binding-container :info="vehicleBindingDialog"
                                       @onComplete="handleBindingComplete"></vehicle-binding-container>
        </el-dialog>
        <!--转会 选择供应商弹窗 begin-->
        <driver-change-supplier-dialog :dialogVisible="driverSupplierDialog.show"
                                       :info="driverSupplierDialog.data"
                                       @onClose="driverSupplierDialog={}"
                                       @success="searchClick()">
        </driver-change-supplier-dialog>
        <!--- 转会选择供应商弹窗 end-->
    </div>
</template>


<script type="text/ecmascript-6">
import {formatDate} from "@/common/js/Utils";
import {getCityData} from "@/urls/index";
// import comListSearch from "../../../components/comListSearch/index.vue";
import SeatModel from "@/components/seatModel/index";
import {seatModelList, getSeatModel} from "@/data/index";
import {getStatusInfo} from "../service"
import SectionContent from "@/components/sectionContent/index.vue";
import VehicleBindingContainer from '../components/vehicle-binding-container.vue'
import {orderStatusList} from "@/data/index";
import driverChangeSupplierDialog from './driver-change-supplier-dialog.vue'
import fleetNameSearchInput from '../components/fleetNameSearchInput/index'
import LoginLogPopover from './LoginLogPopover'
// import EpidemicPreventionInspectionPopover from './EpidemicPreventionInspectionPopover'
import {
    applyExportDriverListApi,
    cancelDriverCancelAccountApi,
    confirmDriverCancelAccountApi, customerDriverCancelAccountApi,
    deleteInvalidDriverApi, getDriverOrderByNoCompleteApi
} from "@/www/urls/driverManage";
import dayjs from 'dayjs'

export default {
    //定义模版数据
    name: "driverList",
    data() {
        return {
            form: {
                personalCar: null,//是否自带车带车
                driverAuditStatus: "", // 司机信息
                driverCertificateAuditStatus: "", // 绑定车辆
                cancelAccountStatus: '',//注销状态
                driverFlagStatus: '',
                isValid: "", // 是否可用
                carType: 0,
                supplierName: '',
                driverLicenseNumber: ''
            },
            driverList: [],
            listLoading: false,
            dialogUpperLower: false,
            seatModelList: seatModelList,
            productShapeOp: [],
            supplierList: [], // 供应商列表
            pageData: {
                // 列表分页
                pageSize: 10, // 每页显示条目个数
                totalPage: 1, // 总条目个数
                currentPage: 1 // 当前页数
            },
            serverCityList: [],
            supplierName: "",
            driverFirstCount: 0,
            driverSecondCount: 0,
            driverNetFirstCount: 0,
            driverNetSecondCount: 0,
            cancelAccountStatusCount: 0,
            dialogTableVisible: false,
            logList: [],
            vehicleBindingDialog: {
                show: false,
            },
            driverSupplierDialog: {
                show: false,
                data: {}
            },//转会弹窗
            evaluationListInfo: [], // 评价
            driverAverage: 0,
            showSelection: false,
            applyExportLoading: false
        };
    },
    components: {
        SectionContent,
        // comListSearch,
        SeatModel,
        VehicleBindingContainer,
        driverChangeSupplierDialog,
        fleetNameSearchInput,
        LoginLogPopover,
        // EpidemicPreventionInspectionPopover
    },
    //计算属性
    computed: {
        userInfo() {
            return this.$store.state.loginUserInfo;
        },
        showApplyExport() {
            return ['总部管理员', '总部运力管理员', "总部超级管理员"].includes(this.userInfo.roleName)
        },
        evaluationListInfoData() {
            return this.evaluationListInfo.filter(({evaluationType}) => evaluationType === 0)
        }
    },
    //主件被加载完成
    mounted: function () {
        this.searchClick();
        this.getServerCity();
        this.getStatusInfo();
    },
    //定义事件方法
    methods: {
        async getServerCity() {
            const ret = await getCityData(this, "2");
            if (ret) this.serverCityList = ret;
        },
        createFilter(itemString) {
            return restaurant => {
                return restaurant.supplierName.indexOf(itemString) !== -1;
            };
        },
        async querySearchSupplier(supplierString, cb) {
            // 供应商列表
            let form = {
                companyName: supplierString
            };
            let supplierLists = [];
            let res = await this.http(
                "/galaxySupplier/supplierInfoPageList",
                form,
                "POST"
            );
            if (res.success) {
                supplierLists = res.data.data;
                let supplierRes = supplierString
                    ? supplierLists.filter(this.createFilter(supplierString))
                    : supplierLists;
                // 调用 callback 返回建议列表的数据
                cb(supplierRes);
            }
        },
        createFilterPlate(itemString) {
            return restaurant => {
                return restaurant.carNumberPlate.indexOf(itemString) === 0;
            };
        },
        async querySearcPlate(query, cb) {
            // 供应商列表
            let plateList = [];
            let res = await this.http(
                `/galaxyDriverApproveApi/doGalaxyGetCarInfoByNumberPlate/${query}`
            );
            if (res.success) {
                plateList = res.data;
                let plateLists = query
                    ? plateList.filter(this.createFilterPlate(query))
                    : plateList;
                // 调用 callback 返回建议列表的数据
                cb(plateLists);
            }
        },
        resetClick(defaultForm = {}) {
            // 重置
            this.form = {
                personalCar: null,//是否自带车带车
                driverAuditStatus: "", // 司机信息
                driverCertificateAuditStatus: "", // 绑定车辆
                driverFlagStatus: '',
                isValid: "", // 是否可用
                carType: 0,
                supplierName: '',
                ...defaultForm
            };

            this.$refs.fleetNameSearch.clear()
            this.supplierName = null;
            this.searchClick();
        },
        pageChange(val) {
            this.pageData.currentPage = val;
            this.searchClick();
        },
        getSelectFleetName(info) {
            this.form.fleetId = info.id;
            this.driverFleetName = info.fleetName
        },
        getSearchForm() {
            let form = JSON.parse(JSON.stringify(this.form));
            form.pageIndex = this.pageData.currentPage;
            form.pageSize = this.pageData.pageSize;
            delete form.carBrandModel;
            if (this.form.carBrandModel && this.form.carBrandModel.length > 0) {
                form.carBrand = this.form.carBrandModel[0];
                form.carModel = this.form.carBrandModel[1];
            }

            if (form.createOrderTime) {
                form.createTimeStart = +new Date(form.createOrderTime[0]);
                form.createTimeEnd = dayjs(form.createOrderTime[1]).endOf('day').valueOf();
                delete form.createOrderTime;
            }
            if (form.carType === 0) delete form.carType;
            return form;
        },
        async searchClick() {
            this.loadingShow();
            const form = this.getSearchForm();
            let res = await this.http(
                "/galaxyDriverApproveApi/doDriverInfoApprovalList",
                form,
                "POST"
            );
            this.loadingHide();
            if (res.success) {
                this.driverList = res.data.data;
                this.pageData.totalPage = res.data.total;
            }
        },
        goEdit(data) {
            this.$router.push(`/driverDetail/${data.id}`);
        },
        handleSelect(item) {
            this.supplierName = item.supplierName;
            this.form.supplierId = item.id;
        },
        handleSelectPlate(item) {
            this.form.carNumberPlate = item.carNumberPlate;
        },
        async enable(index, bool) {
            let form = {
                driverStatus: bool ? 1 : 0,
                loginUserId: this.driverList[index].loginUserId
            };
            let res = await this.http(
                "/galaxyDriverApproveApi/doGalaxyDriverStatusChange",
                form,
                "POST"
            );
            if (res.success) {
                this.$message.success("操作成功");
                let data = JSON.parse(JSON.stringify(this.driverList[index]));
                data.isValid = bool ? 1 : 0;
                this.$set(this.driverList, index, data);
            }
        },
        async getStatusInfo() {
            let res = await this.http(
                "/galaxyDriverApproveApi/doDriverAuditStatusCount",
                {},
                "POST"
            );
            if (res.success) {
                this.driverFirstCount = res.data.driverFirstAuditStatusCount;
                this.driverSecondCount = res.data.driverSecondAuditStatusCount;
                this.driverNetFirstCount = res.data.driverNetCarFirstAuditStatusCount;
                this.driverNetSecondCount = res.data.driverNetCarSecondAuditStatusCount;
                this.cancelAccountStatusCount = res.data.cancelAccountStatusCount
            }
        },
        formatCarModel(type) {
            let info = {};
            if (type) {
                info = getSeatModel(parseInt(type));
            }
            return info;
        },
        formatStatus(status) {
            let info = getStatusInfo(status);
            // console.log(info, type);
            return info;
        },
        async showLog(item) {
            let res = await this.http(`/galaxyDriverApproveApi/doDriverInfoLogList/${item.driverMobile}`);
            console.log(res);
            this.dialogTableVisible = true;
            if (res.success) {
                if (res.data && res.data.length > 0) {
                    this.logList = res.data.sort((a, b) => {
                        return b.createTime - a.createTime
                    });
                } else {
                    this.logList = []
                }
            }
        },
        async handleEvaluate(list) {
            let res = await this.http(`/galaxyDriverApproveApi/selectOrderEvaluationRecordByDriverId/${list.id}`)
            if (res.success && res.data) {
                this.evaluationListInfo = res.data || [];
                let totalScore = 0;
                this.driverAverage = 0;
                if (this.evaluationListInfo.length > 0) {
                    this.evaluationListInfo.forEach(item => {
                        totalScore += item.evaluationLevel || 1
                    })
                    this.driverAverage = parseInt((totalScore / this.evaluationListInfo.length).toFixed(1))
                }
            }
        }, // 评价
        formatOrderStatus(status) {
            let res = {};
            orderStatusList.some((item) => {
                if (item.value == status)
                    res = item;
            })
            return res.name;
        },
        //查询司机当前是否有未完成的订单
        driverHaveRunOrder: async function (data) {
            const {id, carNumberPlate} = data;
            //查询司机是否有未完成订单
            this.loadingShow();
            const result = await this.http('/galaxyOrder/selectOrderInfoByDriverAndCar', {
                driverGuiderNo: id,
                carNumberPlate
            }, 'POST');
            this.loadingHide();
            let orderMsg = '';
            if (result.success && result.data) {
                if (result.data.length > 0) {
                    orderMsg = `<div style="color:#1989FA;padding-left: 70px;"><p>当前未完成订单：</p>`
                    result.data.map(({orderNo, orderStatus, reserveTime}) => {
                        orderMsg += `<p>${this.formatOrderStatus(orderStatus)}：${orderStatus === 41 ? `${formatDate(reserveTime, 'yyyy-MM-dd hh:mm')},` : ''}${orderNo}</p>`
                    })
                    orderMsg += '</div>'
                }
                return orderMsg;
            } else {
                return false;
            }
        },
        //显示车辆绑定弹窗
        async handleVehicleBindingDialog(data, type) {
            //绑定
            if (type)
                this.vehicleBindingDialog = {
                    ...data,
                    show: true
                }
            //解绑
            else {
                try {
                    const {
                        driverName,
                        driverMobile,
                        supplierName,
                        id: driverId,
                        carType,
                        carModel,
                        carBrand,
                        carColor,
                        carNumberPlate
                    } = data;
                    //查询当前司机是否有未完成订单
                    const orderMsg = await this.driverHaveRunOrder(data);
                    if (orderMsg === false)
                        return;

                    let msg = `
                        <div style="padding-left: 40px;">
                        <p>为以下司机和车辆解除绑定关系：</p>
                        <p>司机信息：${driverName}，${driverMobile}</p>
                        <p style="padding-left: 70px">${supplierName}</p>`;

                    let msg2 = `<p>车辆信息：${supplierName}</p>
                        <p style="padding-left: 70px">${carNumberPlate}</p>
                        <p style="padding-left: 70px">${carBrand}${carModel}，${this.formatCarModel(carType).name}，${carColor}车辆</p>
                        </div>
                        `
                    await this.$confirm(msg + orderMsg + msg2, '车辆解绑', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        dangerouslyUseHTMLString: true

                    })
                    this.loadingShow()
                    const ret = await this.http('/galaxyDriverApproveApi/driverRemoveBindCar', {
                        driverId,
                    }, 'POST');
                    if (ret.success && ret.data) {
                        await this.searchClick();
                        this.$message.success('解绑绑定成功！');
                    }
                } finally {
                    this.loadingHide();
                }
            }
        },
        //车辆绑定完成
        handleBindingComplete() {
            this.searchClick();
            this.vehicleBindingDialog = {
                show: false
            }
        },
        //转会
        async handleDriverChangeSupplier(data) {
            //查询当前司机是否有未完成订单
            const orderMsg = await this.driverHaveRunOrder(data);
            if (orderMsg === false)
                return false;
            //有未完成的订单 无法转会
            if (orderMsg)
                try {
                    return this.$alert(orderMsg, '转会失败！', {
                        confirmButtonText: '知道了',
                        dangerouslyUseHTMLString: true
                    });
                } catch (e) {
                    console.log(e);
                }
            //继续转会
            this.driverSupplierDialog = {
                show: true,
                data
            }
        },
        //删除司机
        async handleDeleteDriver({id}) {
            try {
                this.loadingShow()
                const ret = await deleteInvalidDriverApi(id);
                if (ret.success && ret.data) {
                    this.$message.success("删除成功！");
                    this.searchClick();
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loadingHide();
            }
        },
        async getListData(pageIndex) {
            const form = this.getSearchForm();
            form.pageIndex = pageIndex;
            form.pageSize = 50;
            let res = await this.http("/galaxyDriverApproveApi/doDriverInfoApprovalList", form, "POST");
            if (res.success) {
                return res.data;
            }
        },
        //打印全部数据
        async handlePrintAll(list = [], pageIndex = 1) {
            let total = 0;
            try {
                this.loadingShow()
                const ret = await this.getListData(pageIndex)
                if (ret && ret.data) {
                    list.push(...ret.data)
                    total = ret.total
                }
            } finally {
                if (pageIndex * 50 < total) {
                    this.handlePrintAll(list, pageIndex + 1);
                } else {
                    this.loadingHide();
                    if (!list.length) {
                        this.$alert('当前搜索条件下无数据', '提示', {
                            type: "error"
                        });
                    } else {
                        this.$router.push(`/infoPrint/0?ids=${list.map(({loginUserId}) => loginUserId).join(',')}`)
                    }
                }
            }
        },
        //批量打印
        handleBatchPrint() {
            if (!this.selection || !this.selection.length)
                return this.$alert('请先选择打印项', '提示', {
                    type: "error"
                });
            this.showSelection = false;
            this.$router.push(`/infoPrint/0?ids=${this.selection.map(({id}) => id).join(',')}`)
        },
        handleSelectionChange(selection) {
            this.selection = selection;
        },
        //申请导出
        async handleApplyExport() {
            try {
                console.log(this.serverCityList);
                this.applyExportLoading = true;
                await this.$confirm('确定申请导出？', '提示');
                const ret = await applyExportDriverListApi({
                    ...this.getSearchForm(),
                    supplierName: this.supplierName,
                    driverFleetName: this.driverFleetName,
                    cityName: (this.serverCityList.find(({shortCode}) => shortCode === this.form.cityId) || {}).city,
                })
                if (ret.success) {
                    this.$message.success('导出申请提交成功~')
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.applyExportLoading = false;
            }
        },
        //获取注销待审核状态司机
        handleGetCancelAccountStatus() {
            this.resetClick({
                cancelAccountStatus: 2
            });
        },
        //撤销注销
        async handleCancelCancellation({id, loginUserId}) {
            try {
                await this.$confirm('撤回司机帐号注销申请？', '确认撤回')
                this.loadingShow()
                const ret = await cancelDriverCancelAccountApi({
                    id,
                    loginUserId
                })
                if (ret.success) {
                    this.$message.success('撤回成功！')
                    await this.searchClick()
                    await this.getStatusInfo()
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loadingHide()
            }
        },
        //确定注销
        async handleConfirmCancellation({id}) {
            try {
                await this.$confirm('请务必确认司机帐号相关的订单款项已结清、无客诉和资金问题遗留', '确认注销')
                this.loadingShow()
                const ret = await confirmDriverCancelAccountApi({
                    id
                })
                if (ret.success) {
                    this.$message.success('注销成功！')
                    await this.searchClick()
                    await this.getStatusInfo()
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loadingHide()
            }
        },
        //客服申请注销
        async handleApplyCancellation({id, loginUserId}) {
            try {
                await this.$confirm('提交司机帐号注销申请？', '确认提交')
                this.loadingShow()
                const ret = await getDriverOrderByNoCompleteApi({
                    id
                })
                if (ret.success) {
                    if (!ret.data)
                        return this.$message.error('当前司机还有未完成订单，无法注销')
                    const res = await customerDriverCancelAccountApi({
                        driverId: id,
                        driverLoginId: loginUserId
                    })
                    if (res.success && res.data) {
                        await this.searchClick()
                        await this.getStatusInfo()
                        this.$message.success('提交成功！')
                    } else this.$message.error(res.errors[0].message)
                } else this.$message.error(ret.errors[0].message)
            } finally {
                this.loadingHide()
            }
        }
    },
    //监听模版变量
    watch: {}
};
</script>

