<script>
import {checkCarApi, checkDriverApi} from "@/www/urls/rideHailingService";

export default {
    name: "driver-vehicle-verification",
    data() {
        return {
            loading: false
        }
    },
    props: {
        type: {
            type: Number,
            default: 0//0 司机 1 车辆
        },
        info: {
            type: Object,
            default: () => ({})
        }
    },
    computed: {
        getAddress() {
            let address;
            let city = this.type === 0 ? this.info.cityName : this.info.transArea
            switch (city) {
                case '上海市':
                    address = '310100';
                    break
                case '重庆市':
                    address = '510100';
                    break;
                case '广州市':
                    address = '440100';
                    break;
            }
            return address;
        },
    },
    methods: {
        async check() {
            try {
                let msg;
                if (this.type === 0) {
                    if (!this.info.driverLicenseNumber)
                        msg = '缺少司机身份证号'
                    if (!this.info.driverName)
                        msg = '缺少司机姓名'
                    if (!this.info.certificateNo)
                        msg = '缺少资格证号'
                }
                if (this.type === 1) {
                    if (!this.info.carNumberPlate)
                        msg = '缺少车牌号'
                    if (!this.info.vin)
                        msg = '缺少车辆识别代码'
                }
                if (msg)
                    return this.$alert(msg, '提示', {
                        type: 'error'
                    })
                this.loading = true;
                let ret;
                if (this.type === 0) {
                    ret = await checkDriverApi({
                        idCardNumber: this.info.idCardNumber,
                        driverName: this.info.driverName,
                        certificateNo: this.info.certificateNo,
                        address: this.getAddress
                    })
                }
                if (this.type === 1) {
                    ret = await checkCarApi({
                        carNumberPlate: this.info.carNumberPlate,
                        address: this.getAddress,
                        vin: this.info.vin
                    })
                }
                this.$alert(ret.data === 'true' ? '证照有效' : "查无此证", '查询结果', {
                    type: ret.data === 'true' ? 'success' : 'error'
                })
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<template>
    <el-button :loading="loading" :disabled="!getAddress" type="primary" v-bind="$attrs" @click="check">在线查验
    </el-button>
</template>

<style scoped lang="scss">

</style>
